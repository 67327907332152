<template>
  <div class="relative flex flex-1 items-end">
    <input
      v-model="text"
      class="border-px peer max-h-[44px] min-w-0 flex-1 rounded-md rounded-r-none border-r-0 border-grey-light p-3 pl-10 leading-none shadow-[0_12px_33px_0px_rgba(5,58,65,0.04)] transition-colors duration-300 placeholder:text-grey focus:border-blue focus:border-transparent focus:ring-0 focus:placeholder:text-blue lg:max-h-[46px] lg:p-2.5 lg:pl-12 lg:text-lg lg:leading-none"
      type="text"
      :placeholder="$t('search')"
      @keydown.enter="handleSearch"
    />
    <SearchIcon
      class="absolute left-3 top-2.5 h-5 w-5 text-grey transition-colors duration-300 peer-focus:text-blue lg:h-auto lg:w-auto"
    />
    <Button
      :title="$t('search')"
      class-name-text="text-base !leading-snug lg:leading-snug"
      class="rounded-md !rounded-l-none !border-0 !py-2.5 px-8 lg:flex lg:rounded-md lg:!py-3 lg:px-8"
      :is-loading="isLoading"
      @click="handleSearch"
    />
  </div>
</template>

<script setup>
import { useRouter } from '#app'
import { ref } from 'vue'
import { Button } from 'refresh-ui'
import SearchIcon from '@/components/atoms/Icons/Search.vue'
import { useSearchPath } from '@/composables/useSearchPath'

const searchPath = useSearchPath()

const router = useRouter()
const text = ref('')
const isLoading = ref(false)

const handleSearch = () => {
  if (!text.value) return
  isLoading.value = true
  router.push({ path: searchPath, query: { text: text.value } })
  setTimeout(() => {
    isLoading.value = false
    text.value = ''
  }, 2000)
}
</script>
